import styled from "styled-components";
import theme from "../../theme";

const WrapperMainBanner = styled.div`
    background-color: ${theme.backgroundColor.green};
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 70px 0px;
    padding-left: 18%;
    padding-right: 18%;
    flex-wrap: wrap;  
    
    .title, .ilustration{
        width: 50%;
    }

    .ilustration {
        transform: translateX(-10px) translateY(-25px);

        .family {
            display: flex;
            align-items: end;
        }

        img {
            width: 100%;
            user-select: none;
        }
    }

    .title {
        font-size: 1.7rem;
        font-family: ${theme.fontFamily.extraLight};
        color: white;
        margin-top: 70px;
        line-height: 2.7rem;
        transform: translateY(30px) translateX(30px);

        .bold{
            font-size: 2rem;
            color: #111;
            font-family: ${theme.fontFamily.extraBold};
        }
    }

    .search_box{
        width: calc(100% - 30px);
        border-radius: 100px;
        padding: 7px 7px;
        display: flex;
        justify-content: space-between;
        background-color: ${theme.fontColor.white};
        margin-top: 30px;

        .input_box {
            border: 2px solid ${theme.backgroundColor.black7};
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
            display: flex;
            align-items: center;
            width: calc(53.96% - 4px);

            svg {
                font-size: 1.2rem;
                margin-left: 30px;
            }

            div {
                width: 100%;
            }
 
            input {
                border: none;
                outline: none;
                width: 100%;
                font-size: 1rem ;
                margin-left: 20px;
                transform: translateY(1px);
                font-family: ${theme.fontFamily.light};
                background-color: rgba(0,0,0,0);

                &::placeholder{
                    color: ${theme.fontColor.extraWhite};
                }
            }
        }

        .select-box {
            display: flex;
            align-items: center;
            width: 31.19%;
            border: 2px solid ${theme.backgroundColor.black7};
            position: relative;

            .custom-selectbox {
                display: flex;
                flex-direction: column;
                position: relative;
                width: 97.8%;
                font-size: 1rem;

                .value {
                    margin-left: 5%;
                    cursor: pointer;
                    user-select: none;
                }

                .options { 
                    height: 250px;
                    overflow: auto;
                    z-index: 2;
                    position: absolute;
                    background-color: white;
                    padding: 5px;
                    display: flex;
                    flex-direction: column;
                    margin-top: 40px;
                    margin-left: -2px;
                    width: 100%;
                    gap: 10px;
                    border: 1px solid ${theme.backgroundColor.black7};
                    transform: translateY(-50px);
                    transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out, visibility 0s linear 0.15s;
                    visibility: hidden;
                    user-select: none;
                    opacity: 0;

                    &::-webkit-scrollbar {
                        width: 10px; 
                    }

                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #888; 
                        border-radius: 5px; 
                    }

                    .option {
                        padding: 5px;
                        cursor: pointer;
                        
                        &:hover {
                            background-color: #f3f0ff;
                        }

                        &.selected {
                            background-color: ${theme.fontColor.green};
                            color: white;
                        }
                    }

                    &.show {
                        transform: translateY(0px);
                        opacity: 1;
                        visibility: visible;
                        transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out, visibility 0s; 
                    }
                }
            }
            
            select {
                background-color: white;
                cursor: pointer;
                padding: 0px 25px;
                outline: none;
                border: none;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                font-size: 1.2rem ;
                color: ${theme.fontColor.extraWhite};
                font-family: ${theme.fontFamily.light};
                
                &::-ms-expand {
                    display: none;
                }

                option:checked {
                    color: ${theme.fontColor.extraWhite};
                }
            }

            svg {
                font-size: 1.3rem ;
                position: absolute;
                right: 25px;
                transition-property: transform;
                transition-duration: 0.3s;
                cursor: pointer;

                &.actived{
                    transform: rotateX(180deg);
                }
            }
        }

        .button {
            display: flex;
            align-items: center;
            gap: 10px;
            text-decoration: none;
            justify-content: center;
            gap: 10px;
            padding: 7px 12px;
            width: 11.80%;
            text-align: center;
            background-color: ${theme.backgroundColor.green};
            font-family: ${theme.fontFamily.medium};
            color: ${theme.fontColor.white};
            font-size: 1.3rem ;
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
            cursor: pointer;

        }
    }
 
    @media (max-width: ${theme.breackpoint.mobile_2}) {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 10px;
        padding-bottom: 50px;
        margin-bottom: 50px;

        .title {
            width: 100%;
            font-size: 2rem;
            line-height: 2.4rem;
            text-align: center;
            margin-top: 40px;
            transform: translateY(0px) translateX(0px);
        }

        .ilustration {
            display: none;
        }

        .search_box {
            padding: 15px;
            padding: 7px 0px;
            flex-direction: column;
            align-items: flex-end;
            background-color: initial;
            width: 100%;
            gap: 10px;

            .input_box {
                background-color: white;
                width: calc(100% - 20px);
                border: 2px solid white;
                border-radius: 7px;
                padding: 7px 10px;
                gap: 5px;

                svg {
                    margin-left: 0;
                }

                input {
                    font-size: 1.2rem;
                    margin-left: 0px;
                }

            }

            .select-box {
                background-color: white;
                width: calc(100% - 20px);
                border: 2px solid white;
                border-radius: 7px;
                padding: 10px 10px;
                gap: 5px;
                
            }

            .button {
                width: auto;
                gap: 10px;
                padding: 3px 10px;
                display: flex;
                align-items: center;
                background-color: white;
                color: ${theme.fontColor.green};
                border-radius: 5px;

                svg {
                    font-size: 1rem;
                }
            }
        }
    }

    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        /*flex-direction: column;*/
        padding: 30px 0px;
        padding-left: 10%;
        padding-right: 10%;

        .title {
            margin-bottom: 40px;
            font-size: 1.4rem;
            line-height: 2rem;
        }

        .ilustration {
            /*margin: 0 auto;*/
            margin-top: 30px;

            .family {
                img {
                    width: 100%;
                }
            }
        }

        .search_box {
            
            .input_box {
                input {
                    font-size: .85rem;
                }
            }

            .select-box {
                select {
                    width: calc(100% - 50px);
                    font-size: .85rem;
                }

                .value, .option {
                    font-size: .85rem;
                }

                svg {
                    font-size: 1.6rem;
                }
            }

            .button {
                font-size: 1rem;
            }
        }


    }

    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px 0px;
        padding-left: 12%;
        padding-right: 12%;

        .title {
            width: 50%;
            margin-bottom: 40px;
            font-size: 1.5rem;
            line-height: 2rem;
            line-height: normal;
        }

        .ilustration {
            width: 44%;

            .family {
                img {
                    width: 100%;
                    margin-top: 60px;
                }
            }
        }

        .search_box {
            
            .input_box {
                input {
                    font-size: 1rem;
                }
            }

            .select-box {
                select {
                    width: calc(100% - 50px);
                    font-size: 1rem;
                }

                .option {
                    font-size: .9rem;
                }

                svg {
                    font-size: 1.6rem;
                }
            }

            .button {
                font-size: 1rem;
            }
        }


    }

    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px 0px;
        padding-left: 15%;
        padding-right: 15%;

        .title {
            width: 50%;
            margin-bottom: 40px;
            font-size: 1.8rem;
            line-height: 2.4rem;
        }

        .ilustration {
            width: 44%;

            .family {
                img {
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }

        .search_box {
            
            .input_box {
                input {
                    font-size: 1rem;
                }
            }

            .select-box {
                select {
                    width: calc(100% - 50px);
                    font-size: .9rem;
                }

                .value {
                    font-size: .9rem;
                }

                .options {
                    .option {
                        font-size: .9rem;
                    }
                }

                svg {
                    font-size: 1.6rem;
                }
            }

            .button {
                font-size: 1rem;
            }
        }


    }

    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px 0px;
        padding-left: 15%;
        padding-right: 15%;

        .title {
            width: 50%;
            margin-bottom: 40px;
            font-size: 2rem;
            line-height: 2.7rem;
        }

        .ilustration {
            width: 44%;

            .family {
                img {
                    width: 100%;
                    margin-top: 60px;
                }
            }
        }

        .search_box {
            
            .input_box {
                input {
                    font-size: 20px;
                }
            }

            .select-box {
                select {
                    width: calc(100% - 50px);
                    font-size: 20px;
                }

                svg {
                    font-size: 35px;
                }
            }

            .button {
                font-size: 20px;
            }
        }


    }

`

export default WrapperMainBanner