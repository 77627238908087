import styled from "styled-components"
import theme from "../../theme"

const WrapperRegisterProperty = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px ${props => (props.step === 1 ? "0" : "13%")};
    

    .title_session{
        text-align: center;
        font-size: 2rem;
        font-family: ${theme.fontFamily.light};

        .bold {
            font-weight: 600;
            font-family: ${theme.fontFamily.medium};
        }
    }

    .specs_one{
        display: flex;
        justify-content: center;
        width: 60%;

        .spec {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .title {
                font-size: 1.1rem;
                font-family: ${theme.fontFamily.medium};
                color: ${theme.fontColor.green};
            }

            .inputbox {
                border: 1px solid gray;
                display: flex;
                align-items: center;
                padding: 0px 20px;
                border-radius: 100px;
                height: 30px;
                width: 100%;

        
                input {
                    outline: none;
                    border: none;
                    width: 100%;
                    font-weight: 600;

                    &::placeholder{
                        opacity: .5;
                    }
                }

                .sufix {
                    color: ${theme.fontColor.green};
                    font-family: ${theme.fontFamily.medium};
                }
            }

            .select-box {
                display: flex;
                align-items: center;
                width: 100%;
                border: 1px solid ${theme.backgroundColor.black7};
                border-radius: 100px;
                position: relative;

                .custom-selectbox {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: 97.8%;
                    padding: 6px 0px;
                    font-size: .85rem;

                    .value {
                        margin-left: 5%;
                        cursor: pointer;
                        user-select: none;
                    }

                    .options { 
                        height: 250px;
                        overflow: auto;
                        z-index: 2;
                        position: absolute;
                        background-color: white;
                        padding: 5px;
                        display: flex;
                        flex-direction: column;
                        margin-top: 28px;
                        margin-left: -2px;
                        width: 100%;
                        gap: 10px;
                        border: 1px solid ${theme.backgroundColor.black7};
                        transform: translateY(-50px);
                        transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out, visibility 0s linear 0.15s;
                        visibility: hidden;
                        user-select: none;
                        opacity: 0;

                        &::-webkit-scrollbar {
                            width: 10px; 
                        }

                        &::-webkit-scrollbar-track {
                            background: #f1f1f1;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: #888; 
                            border-radius: 5px; 
                        }

                        .option {
                            padding: 5px;
                            cursor: pointer;
                            font-size: .9rem;

                            &.selected {
                                background-color: #01bbb6;
                                color: white;
                            }
                        }

                        &.show {
                            transform: translateY(0px);
                            opacity: 1;
                            visibility: visible;
                            transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out, visibility 0s; 
                        }
                    }
                }
                
                select {
                    background-color: white;
                    cursor: pointer;
                    padding: 0px 25px;
                    outline: none;
                    border: none;
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    font-size: 1.5rem !important;
                    color: ${theme.fontColor.extraWhite};
                    font-family: ${theme.fontFamily.light};
                    
                    &::-ms-expand {
                        display: none;
                    }

                    option:checked {
                        color: ${theme.fontColor.extraWhite};
                    }
                }

                svg {
                    font-size: 1.2rem !important;
                    position: absolute;
                    right: 25px;
                    transition-property: transform;
                    transition-duration: 0.3s;
                    cursor: pointer;

                    &.actived{
                        transform: rotateX(180deg);
                    }
                }
            }

            .t5 {
                width: 65%;
            }
        }
    }

    .specs_two {
        margin-top: 30px;
        display: flex;
        gap: 40px;
        width: 50%;
        justify-content: center;

        .spec {
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 3px;

            .title {
                font-size: 1.3rem;
                font-family: ${theme.fontFamily.medium};
                color: ${theme.fontColor.green};
            }

            .checkbox_select {
                width: 65%;
                display: flex;
                justify-content: space-between;
                gap: 5px;
 
                .checkbox {
                    width: 18%;
                    padding: 0px 2px;
                    border: 1px solid gray;
                    text-align: center;
                    border-radius: 3px;
                    cursor: pointer;
                    

                    &.active {
                        background-color: ${theme.fontColor.green};
                        border: 1px solid ${theme.fontColor.green};
                        color: white;
                    }
                }
            }
        }
    }

    .specs_three {
        display: flex;
        justify-content: center;
        width: 60%;
        align-items: center;
        margin-top: 40px;
        gap: 15px;

        .spec {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 3px;

            &.special {
                width: 20%;

                .inputbox {
                    width: 50%;

                    input {
                        width: 100%;
                    }

                    
                }
            }

            .title {
                font-size: 1.3rem;
                color: ${theme.fontColor.green};
                font-family: ${theme.fontFamily.medium}
            }

            .inputbox {
                padding: 2px 20px;
                border: 1px solid gray;
                border-radius: 100px;
                display: flex;
                align-items: center;
                gap: 2px;

                .prefix {
                    font-family: ${theme.fontFamily.medium};
                    color: ${theme.fontColor.green};
                }

                input {
                    border: none;
                    outline: none;
                    font-family: ${theme.fontFamily.medium};
                    transform: translateY(1px);

                }
            }

            .buttons {
                width: 100%;
                display: flex;
                gap: 5px;

                .button {
                    cursor: pointer;
                    width: 100%;
                    text-align: center;
                    padding: 2px 0px;
                    border: 1px solid gray;
                    border-radius: 100px;

                    &.actived {
                        background-color: ${theme.fontColor.green};
                        border: 1px solid ${theme.fontColor.green};
                        color: white;
                    }
                }
            }
        }
    }

    .specs_four {
        display: flex;
        
        flex-wrap: wrap;
        width: 65%;
        margin-left: 30px;
        margin-top: 40px;
        gap: 5px;

        .spec {
            width: 31%;
            margin-top: 5px;
            display: flex;
            align-items: center;
            gap: 5px;

            input {
                display: none;
            }

            label {
                position: relative;
                padding-left: 2rem;
                cursor: pointer;
                font-size: 1rem;
                user-select: none;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 24px;
                    height: 24px;
                    border: 1px solid black;
                    border-radius: 50%;
                    background-color: white;
                    transition: all 0.3s ease; 
                }
            }

            input[type="radio"]:checked + label::before {
                background-color: ${theme.fontColor.green};
            }
        }
    }

    .specs_five {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        margin-top: 30px;


        .title {
            font-size: 2rem;
            font-family: ${theme.fontFamily.medium};
            color: ${theme.fontColor.green};
        }

        textarea {
            width: 100%;
            height: 200px;
            padding: 10px;
            resize: none;
            outline: none;
            border-radius: 10px;
            border: 2px solid ${theme.fontColor.green};
            background-color: #f3f3f3;
            font-size: 1.2rem;
        }

        .info {
            align-self: flex-end;
            font-family: ${theme.fontFamily.medium};
            font-size: .9rem;
        }
    }

    .specs_six {
        margin-top: 40px;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        justify-content: center;
 
        .spec {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
            position: relative;

            .feedback {
                position: absolute;
                bottom: 0;
                margin-bottom: -20px;
                color: red;
            }


            .title {
                font-size: 1.3rem;
                font-family: ${theme.fontFamily.medium};
                color: ${theme.fontColor.green};
            }

            .input-container {
                display: flex;
                gap: 10px;
            }

            input {
                padding: 7px 20px;
                width: calc(95% - 40px);
                outline: none;
                border: 1px solid gray;
                border-radius: 100px;
                font-size: 1.05rem;

                &[disabled] {
                    background: rgba(0,0,0,.15);
                    border: 1px solid rgba(0,0,0,.20);
                }
            }
        }
    }

    .specs_seven {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;

        .title {
            font-size: 2rem;
            font-family: ${theme.fontFamily.medium};
            color: ${theme.fontColor.green};
            margin-bottom: -5px;
            text-align: center;
            align-self: center !important;
        }

        .description {
            font-size: 1rem;
            align-self: flex-start;
            color: #121212 !important;
            font-family: ${theme.fontFamily.regular};
        }

        .image-selection {
            margin: 50px 0px;

            label {
                cursor: pointer;
            }
        }

        
    }

    .next-container {
        width: 60%;
        display: flex;
        flex-direction: column;
        margin-top: 5px;

        .description {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 1rem;
            align-self: flex-start;
            color: #121212 !important;
            font-family: ${theme.fontFamily.regular};
            cursor: pointer;

            .checkbox{
                height: .9rem;
                width: .9rem;
                border: 2px solid gray;

                svg {
                    display: none;
                }

                &.checked{
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        display: block;
                    }
                }
            }
        }

        .button{
            &.finish{
                margin-top: 40px;
                text-align: center;
                font-size: 1.1rem;
                background-color: ${theme.fontColor.green};
                color: white;
                width: 20%;
                padding: 4px 0px;
                border-radius: 100px;
                cursor: pointer;
                align-self: center;
            }
            &.deactive{
                margin-top: 40px;
                text-align: center;
                font-size: 1.1rem;
                background-color: ${theme.fontColor.gray};
                color: white;
                width: 20%;
                padding: 4px 0px;
                border-radius: 100px;
                cursor: pointer;
                align-self: center;
            }
        }
        
    }

    

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin: 20px 1%;
        margin-bottom: 80px;
        gap: 20px;
        
        .title_session {
            font-size: 1.5rem;
        }
        
        .specs_one{
            width: 100%;
            align-items: flex-end;
            flex-wrap: wrap;
            justify-content: center;
            
            .spec {
                width: 80%;
                margin-top: 10px;
                position: relative;
                
                .title {
                    font-size: .95rem;
                }

                .inputbox {
                    width: calc(100% - 40px);
                    padding: 4px 20px;
                }

                
            }
        }

        .specs_two{
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;

            .spec {
                width: 48%;

                .title {
                    font-size: 1.2rem;
                }

                .checkbox_select{
                    font-size: 1.2rem;
                    width: 100%;

                    .checkbox {
                        padding: 0px 2px;
                    }
                }
            }
        }

        .specs_three{
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;

            .spec {
                .title {
                    font-size: 1.2rem;
                }

                .inputbox {
                    padding: 8px 20px;
                }

                .buttons {
                    .button {
                        font-size: 1rem;
                        padding: 8px 0px;
                    }
                }
            }
        }

        .specs_four{
            width: 90%;

            .spec {
                width: 49%;

                label {
                    font-size: 1rem;
                    margin-bottom: 5px;

                    &::before {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        .specs_five{
            width: 80%;

            .title {
                font-size: 1.4rem;
            }

            textarea{
                height: 300px;
                font-family: ${theme.fontFamily.medium};
            }
        }

        .specs_six{
            width: 85%;
            flex-direction: column;

            .spec{
                width: 100%;

                .title {
                    font-size: 1.2rem;
                }

                input {
                    padding: 8px 10px;
                    font-size: 1rem;
                }
            }
        }
        
        .specs_seven{
            width: 90%;

            .title {
                font-size: 1.5rem;
            }


            .description {
                font-size: 1rem;
            }
        }

        .next-container {
            width: 90%;
            margin: 0 auto;
            .description {
                width: 100%;
            }
        }

        .button.finish{ 
            font-size: 1.3rem;
            width: 60%;
            padding: 4px 10px;
        }
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        margin: 20px 1%;
        
        .title_session {
            font-size: 1.5rem;
        }
        
        .specs_one{
            width: 80%;
            align-items: flex-end;
            gap: 10px;

            .spec {
                .title {
                    font-size: .85rem;
                }
                .inputbox{
                    width: calc(100% - 40px);
                }
            }
        }

        .specs_two{
            width: 80%;

            .spec {
                .title {
                    font-size: .85rem;
                }

                .checkbox_select{
                    font-size: .8rem;
                }
            }
        }

        .specs_three{
            .spec {
                .title {
                    font-size: .85rem;
                }

                .buttons {
                    .button {
                        font-size: .80rem;
                    }
                }
            }
        }

        .specs_four{
            width: 80%;

            .spec {
                label {
                    font-size: .80rem;
                    margin-bottom: 5px;

                    &::before {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        .specs_five{
            width: 80%;

            .title {
                font-size: 1.2rem;
            }
        }

        .specs_six{
            .spec{
                .title {
                    font-size: .85rem;
                }

                input {
                    padding: 4px 10px;
                    font-size: .8rem;
                }
            }
        }
        
        .specs_seven{

            .title {
                font-size: 1.2rem;
            }

            .description {
                font-size: .75rem;
            }
        }

        .button.finish{
            font-size: 1rem;
            padding: 2px 0px;
        }
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        .title_session {
            font-size: 1.5rem;
        }
        
        .specs_one{
            width: 80%;
            align-items: flex-end;
            gap: 10px;

            .spec {
                .title {
                    font-size: .85rem;
                }
                .inputbox{
                    width: calc(100% - 40px);
                }
            }
        }

        .specs_two{
            width: 80%;

            .spec {
                .title {
                    font-size: .85rem;
                }

                .checkbox_select{
                    font-size: .8rem;
                }
            }
        }

        .specs_three{
            .spec {
                .title {
                    font-size: .85rem;
                }

                .buttons {
                    .button {
                        font-size: .80rem;
                    }
                }
            }
        }

        .specs_four{
            width: 80%;

            .spec {
                label {
                    font-size: .80rem;
                    margin-bottom: 5px;

                    &::before {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        .specs_five{
            width: 80%;

            .title {
                font-size: 1.2rem;
            }
        }

        .specs_six{
            .spec{
                .title {
                    font-size: .85rem;
                }

                input {
                    padding: 4px 10px;
                    font-size: .8rem;
                }
            }
        }
        
        .specs_seven{

            .title {
                font-size: 1.2rem;
            }

            .description {
                font-size: .75rem;
            }
        }

        .button.finish{
            font-size: 1rem;
            padding: 2px 0px;
        }
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        .title_session {
            font-size: 1.7rem;
        }
        
        .specs_one{
            width: 70%;
            align-items: flex-end;

            .spec {
                .title {
                    font-size: 1rem;
                }
                .inputbox{
                    width: calc(100% - 40px);
                }
            }
        }

        .specs_two{
            width: 60%;

            .spec {
                .title {
                    font-size: 1rem;
                }

                .checkbox_select{
                    font-size: .85rem;
                }
            }
        }

        .specs_three{
            .spec {
                .title {
                    font-size: 1rem;
                }

                .buttons {
                    .button {
                        font-size: .85rem;
                    }
                }
            }
        }

        .specs_four{
            width: 72%;

            .spec {
                label {
                    font-size: .85rem;

                    &::before {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        .specs_five{
            width: 60%;

            .title {
                font-size: 1.5rem;
            }
        }

        .specs_six{
            .spec{
                .title {
                    font-size: 1rem;
                }

                input {
                    padding: 5px 14px;
                    font-size: .90rem;
                }
            }
        }
        
        .specs_seven{

            .title {
                font-size: 1.5rem;
            }

            .description {
                font-size: .9rem;
            }
        }

        .button.finish{
            font-size: 1.2rem;
            padding: 4px 0px;
        }
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        
    }
    
`

export default WrapperRegisterProperty