import { useContext, useEffect, useState } from "react";
import WrapperEditProperty from "./EditProperty.styles";
import { FaCheck, FaXmark } from "react-icons/fa6";
import HouseIcon from "../../imgs/icons/camera_3326034.png"
import { IoIosArrowDown } from "react-icons/io";
import theme from "../../theme";
import { AuthContext } from '../../context/AuthContext';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../hooks/firebase";
import { v4 as uuidv4 } from 'uuid';
import styled from "styled-components";
import { FiX } from "react-icons/fi";
import { NotificationContext } from "../../context/NotificationContext";
import ImageUpload from "../../components/image_upload/ImageUpload";
import WrapperRegisterProperty from './../register-property/ResigterProperty.styles';
import CustomSelectBox from "../../components/custom_selectbox_a/CustomSelectBoxA";
 
const EditPropertyPage = () => {
    const isMobile = window.innerWidth <= theme.breackpoint.mobile_2.replace("px", "")
    const {currentUser} = useContext(AuthContext)
    const [data, setData] = useState(false)
    const [inputs, setInputs] = useState([
        { id: "academia", label: "Academia" },
        { id: "aceita_pets", label: "Aceita Pets" },
        { id: "churrasqueira", label: "Churrasqueira" },
        { id: "piscina_aquecida", label: "Piscina Aquecida" },
        { id: "alarme", label: "Alarme" },
        { id: "closet", label: "Closet" },
        { id: "portao_eletrico", label: "Portão Elétrico" },
        { id: "aquecedor_solar", label: "Aquecedor Solar" },
        { id: "cozinha_planejada", label: "Cozinha Planejada" },
        { id: "sala_de_jantar", label: "Sala de Jantar" },
        { id: "ar_condicionado", label: "Ar Condicionado" },
        { id: "elevador", label: "Elevador" },
        { id: "varanda", label: "Varanda" },
        { id: "area_gourmet", label: "Área Gourmet" },
        { id: "energia_solar", label: "Energia Solar" },
        { id: "mobiliado", label: "Mobiliado" },
        { id: "area_de_serviço", label: "Área De Serviço" },
        { id: "escritorio", label: "Escritório" },
        { id: "seguranca_24h", label: "Segurança 24h" },
        { id: "armario_embutido", label: "Armário Embutido" },
        { id: "home", label: "Home" },
        { id: "banheira", label: "Banheira" },
        { id: "interfone", label: "Interfone" },
        { id: "lavabo", label: "Lavabo" },
        { id: "cameras_de_seguranca", label: "Câmeras de Segurança" },
        { id: "lavanderia", label: "Lavanderia" },
        { id: "sala_de_tv", label: "Sala de Tv" },
        { id: "cerca_eletrica", label: "Cerca Elétrica" },
        { id: "piscina", label: "Piscina" },
    ])
    const [selectedInputs, setSelectedInputs] = useState({});
    const [imageFields, setImageFields] = useState([{ id: Date.now(), image: null }]);
    const [bathroomQuantity, setBathroomQuantity] = useState(false)
    const [bedroomQuantity, setBedroomQuantity] = useState(false)
    const [garageQuantity, setGarageQuantity] = useState(false)
    //const [iscondominium, setIsCondominium] = useState(false)
    const [selectboxValue, setSelectBoxValue] = useState(isMobile ? "Imóvel" : "Tipo do Imóvel")
    const [showOptions, setShowOptions] = useState(false)
    const [squareMeter, setSquareMeter] = useState("")
    const [alqueiro, setAlqueiro] = useState(false)
    const [squareMeterConstruction, setSquareMeterConstruction] = useState("")
    const [price, setPrice] = useState("")
    const [condominiumPrice, setCondominiumPrice] = useState("")
    const [IPTU, setIPTU] = useState("")
    const [description, setDescription] = useState("")
    const [CEP, setCEP] = useState("")
    const [city, setCity] = useState("")
    const [street, setStreet] = useState("")
    const [number, setNumber] = useState("")
    const [complement, setComplement] = useState("")
    const [acceptsExchange, setAcceptsExchange] = useState(true)
    const [neightboard, setNeightboard] = useState("")
    const [state, setState] = useState("")
    const { propertyId } = useParams()
    const [images, setImages] = useState([])
    const [imagesBackup, setImagesBackup] = useState([])
    const navigate = useNavigate()
    const {addNotification} = useContext(NotificationContext)
    const [showContactUser, setShowContactUser] = useState(false)

    const handleToggle = (id) => {
      setSelectedInputs((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));

    };

    function setBathroomQuantityByCheckbox(value, e) {
        let parent = e.target.parentElement;
        let buttons = parent.querySelectorAll('.checkbox');

        buttons.forEach(button => {
            button.classList.remove('active');
        });

        e.target.classList.add('active');
        setBathroomQuantity(value)
    }

    function setBedroomQuantityByCheckbox(value, e) {
        let parent = e.target.parentElement;
        let buttons = parent.querySelectorAll('.checkbox');

        buttons.forEach(button => {
            button.classList.remove('active');
        });

        e.target.classList.add('active');
        setBedroomQuantity(value)
    }

    function setGarageQuantityByCheckbox(value, e) {
        let parent = e.target.parentElement;
        let buttons = parent.querySelectorAll('.checkbox');

        buttons.forEach(button => {
            button.classList.remove('active');
        });

        e.target.classList.add('active');
        setGarageQuantity(value)
    }

    function handleSelectOption(value) {
        setSelectBoxValue(value)
        setShowOptions(false)
    }

    function getImages() {
        const result = images.filter(image => {
            let imageUrl = image.url
            if (imageUrl.startsWith("blob")){
                return imageUrl
            }
        }).map(item => {
            return item.url
        })

        return result   
    }

    function getSelectedInputs() {
        return inputs
            .map((item) => {
                if (selectedInputs[item.id]) {
                    // Desestrutura e remove a propriedade 'value'
                    const { value, ...rest } = item;
                    return rest; // Retorna o objeto sem a propriedade 'value'
                }
            })
            .filter((item) => item !== undefined); // Filtra os itens indefinidos
    }

    const handleSetDescription = event => {
        let value = (event.currentTarget.value).slice(0, 1000)
        setDescription(value)
    } 

    const handlePrice = (e) => {
        let value = (e.currentTarget.value).replace(/\D/g, '');
    
        if (value.length > 2) value = value.slice(0, -2) + ',' + value.slice(-2);
    
        let parts = value.split(',');
        let integerPart = parts[0];
        let decimalPart = parts[1] || '';
    
        let formattedValue = '';
        for (let i = integerPart.length - 1, count = 0; i >= 0; i--, count++) {
            formattedValue = integerPart[i] + formattedValue;
            if ((count + 1) % 3 === 0 && i !== 0) {
                formattedValue = '.' + formattedValue;
            }
        }
    
        if (decimalPart) {
            formattedValue += ',' + decimalPart;
        }
    
        setPrice(formattedValue);
    };

    function formatPrice2() {
        let tempPrice = price.split(",")[0].replace(/\./g, "");
        return parseInt(tempPrice);
    }

    const handleCondominium = (e) => {
        let formattedValue = formatPrice(e.currentTarget.value)
    
        setCondominiumPrice(formattedValue);
    }

    const handleIPTU = (e) => {
        let formattedValue = formatPrice(e.currentTarget.value)
    
        setIPTU(formattedValue);
    }

    const formatPrice = (value) => {
        value = value.replace(/\D/g, '');
    
        let formattedValue = '';
        for (let i = value.length - 1, count = 0; i >= 0; i--, count++) {
            formattedValue = value[i] + formattedValue;
            if ((count + 1) % 3 === 0 && i !== 0) {
                formattedValue = '.' + formattedValue;
            }
        }

        return formattedValue
    }

    async function next() {
        const formData = new FormData();
        const data = {
            id: propertyId,
            price: formatPrice2(price),
            active: true,
            acceptsExchange,
            bathroom: parseInt(bathroomQuantity),
            bedroom: parseInt(bedroomQuantity),
            cep: CEP,
            city,
            complement,
            description,
            garage: parseInt(garageQuantity),
            images: getImages(), //Enviar apenas as novas, usar como filtro se começa com https://
            neightboard,
            number,
            otherItems: getSelectedInputs(), // verificar
            squareConstruction: parseInt(squareMeterConstruction),
            squareMeter: parseInt(squareMeter),
            state,
            street,
            type: selectboxValue,
            contactIsVisible: showContactUser
        }

        await Promise.all(
            getImagesToDelete().map(item => {
                const imageUrl = item.url;
                return axios.post(
                    "https://us-central1-alugueimoveis-6808c.cloudfunctions.net/deleteImageFromBucket",
                    { image: imageUrl, idProperty: propertyId }
                );
            })
        );
        const { images, ...property} = data;

        if (images.length > 0) {
            const imagesUpload = images;
          
            const convertBlobToFile = async (blobUrl, index) => {
              const response = await fetch(blobUrl);
              const blob = await response.blob();
              const fileType = blob.type || 'image/jpeg'; 
              
              return new File([blob],`image-${index}.jpg`, { type: fileType });
            };
          
            const uploadImages = async () => {
              for (let i = 0; i < imagesUpload.length; i++) {
                const file = await convertBlobToFile(imagesUpload[i], i);
          
                formData.append(`file[${i}]`, file);
              }
            };
          
            await uploadImages();
        }
        formData.append("data", JSON.stringify(property))
        axios.post(
            "https://us-central1-alugueimoveis-6808c.cloudfunctions.net/editProperty", 
            formData
        ).then(result => {
            if (result.data.message === "Propriedade salva com sucesso") {
                navigate("/meus-imoveis")
                addNotification(result.data.message, "success")
            }
        }).catch(e => {
            console.log(e)
        })
        
    }

    const options = [
        {option: "Apartamento"},
        {option: "Área Comercial"},
        {option: "Casa"},
        {option: "Casa Comercial"},
        {option: "Casa Em Condomínio"},
        {option: "Chácara"},
        {option: "Galpão/Armazém"},
        {option: "Kitnet/Studio"},
        {option: "Prédio Comercial"},
        {option: "Rancho"},
        {option: "Sala Comercial"},
        {option: "Salão Comercial"},
        {option: "Sítio/Fazenda"},
        {option: "Terreno"},
        {option: "Terreno Comercial"},
        {option: "Terreno Em Condomínio"},
    ]

    const getImagesToDelete = () => {
        // Filtra imagens presentes em `imagesBackup` mas ausentes em `images`
        const missingImages = imagesBackup.filter(
            backupImage => !images.some(image => image.url === backupImage.url)
        );
    
        // Loga o array de imagens ausentes
        console.log(missingImages)
        return missingImages;
    };

    const handlSetSquareMeter = event => {
        let value = (event.currentTarget.value).slice(0, 10)
        setSquareMeter(value)
    }

    const handlSetAlqueiro = event => {
        let value = (event.currentTarget.value).slice(0, 10)
        setAlqueiro(value)
    }

    const handlSetSquareMeterConstruction = event => {
        let value = (event.currentTarget.value).slice(0, 10)
        setSquareMeterConstruction(value)
    }

    useEffect(() => {
        if (currentUser === null) return navigate("/")
        if (data === false) {
            const fetchPropertyData = async () => {
                const propertyRef = doc(db, 'properties', propertyId);
                
                try {
                    const docSnap = await getDoc(propertyRef);
                    if (docSnap.exists()) {
                        const fetchedData = docSnap.data()
                        const data = {
                            id: fetchedData.id,
                            ...fetchedData
                        };
                        
                        if (data.ownerId !== currentUser) return navigate("/")
 
                        const otherItems = fetchedData.otherItems.map(item => item.id)
                        const updated = inputs.map(input => {
                            return {
                                ...input,
                                value: otherItems.includes(input.id)
                            };
                        });
                        const teste = {}
                        updated.forEach(item => {
                            if (item.value) {
                                teste[item.id] = item.value
                            }
                        })
                        setSelectedInputs(teste)
                        setImages(data.images.map(url => ({ id: uuidv4(), url })))
                        setImagesBackup(data.images.map(url => ({ id: uuidv4(), url })))
                        setInputs(updated)
                        setData(true)
                        setShowContactUser(data.contactIsVisible)
                        setBathroomQuantity(data.bathroom)
                        setBedroomQuantity(data.bedroom)
                        setGarageQuantity(data.garage)
                        setSelectBoxValue(data.type)
                        setAlqueiro(data.alqueiro)
                        setSquareMeter(data.squareMeter)
                        setSquareMeterConstruction(data.squareConstruction)
                        setPrice(formatPrice((data.price).toString()) + ",00")
                        setDescription(data.description)
                        setCEP(data.cep)
                        setCity(data.city)
                        setStreet(data.street)
                        setNumber(data.number)
                        setComplement(data.complement)
                        setAcceptsExchange(data.acceptsExchange)
                        setNeightboard(data.neightboard)
                        setState(data.state)
                        const imagesField = data.images.map(image => ({
                            id: Date.now(),
                            image: image
                        }))
                        setImageFields([...imagesField, {id: Date.now(), image: null}])
                    } else {
                        navigate("/")
                    }
                } catch (error) {
                    console.error('Error fetching property:', error);
                }
            };
    
            fetchPropertyData(); 
        }
        
    }, []); 

    return <WrapperRegisterProperty>
        <h1 className="title_session">
            Fale um pouco sobre as <br />
            <span className="bold">especificações do seu imóvel.</span>
        </h1>
        <div className="specs_one">
            <div className="spec">
                <span className="title">Selecione o tipo de imóvel</span>
                <CustomSelectBox
                    options={options}
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                    selectboxValue={selectboxValue}
                    handleSelectOption={handleSelectOption}
                />
            </div>
            {!["Apartamento", "Kitnet/Studio", "Sala Comercial", "Sítio/Fazenda"].includes(selectboxValue) && 
                <div className="spec">
                    <span className="title">Metragem Terreno</span>
                    <div className="inputbox t5">
                        <input type="text" value={squareMeter} onChange={handlSetSquareMeter} />
                        <span className="sufix">M²</span>
                    </div>
                </div>
            } 
            {selectboxValue === "Sítio/Fazenda" && 
                    <div className="spec">
                        <span className="title">Alqueire</span>
                        <div className="inputbox t5">
                            <input type="text" value={alqueiro} onChange={handlSetAlqueiro} />
                        </div>
                    </div>
            }
            {!["Sítio/Fazenda", "Área Comercial", "Terreno", "Terreno Comercial", "Terreno Em Condomínio"].includes(selectboxValue) && 
                <div className="spec">
                    <span className="title">Metragem Construção</span>
                    <div className="inputbox t5">
                        <input type="text" value={squareMeterConstruction} onChange={handlSetSquareMeterConstruction}/>
                        <span className="sufix">M²</span>
                    </div>
                </div>
            }
        </div>
        <div className="specs_two">
            {!["Sítio/Fazenda", "Área Comercial", "Terreno", "Terreno Comercial", "Terreno Em Condomínio"].includes(selectboxValue) && 
                <div className="spec">
                    <span className="title">Banheiros</span>
                    <div className="checkbox_select">
                        <span className={`checkbox${bathroomQuantity == 1 ? " active" : ""}`} onClick={(e) => setBathroomQuantityByCheckbox("1", e)}>1</span>
                        <span className={`checkbox${bathroomQuantity == 2 ? " active" : ""}`} onClick={(e) => setBathroomQuantityByCheckbox("2", e)}>2</span>
                        <span className={`checkbox${bathroomQuantity == 3 ? " active" : ""}`} onClick={(e) => setBathroomQuantityByCheckbox("3", e)}>3</span>
                        <span className={`checkbox${bathroomQuantity == 4 ? " active" : ""}`} onClick={(e) => setBathroomQuantityByCheckbox("4", e)}>4</span>
                        <span className={`checkbox${bathroomQuantity == "4+" ? " active" : ""}`} onClick={(e) => setBathroomQuantityByCheckbox("4+", e)}>4+</span>
                    </div>
                </div>
            } 
            {!["Sala Comercial", "Sítio/Fazenda", "Sala Comercial", "Área Comercial", "Galpão/Armazém", "Prédio Comercial", "Terreno", "Terreno Comercial", "Terreno Em Condomínio"].includes(selectboxValue) &&
                <div className="spec">
                    <span className="title">Quartos</span>
                    <div className="checkbox_select">
                        <span className={`checkbox${bedroomQuantity == 1 ? " active" : ""}`} onClick={(e) => setBedroomQuantityByCheckbox("1", e)}>1</span>
                        <span className={`checkbox${bedroomQuantity == 2 ? " active" : ""}`} onClick={(e) => setBedroomQuantityByCheckbox("2", e)}>2</span>
                        <span className={`checkbox${bedroomQuantity == 3 ? " active" : ""}`} onClick={(e) => setBedroomQuantityByCheckbox("3", e)}>3</span>
                        <span className={`checkbox${bedroomQuantity == 4 ? " active" : ""}`} onClick={(e) => setBedroomQuantityByCheckbox("4", e)}>4</span>
                        <span className={`checkbox${bedroomQuantity == "4+" ? " active" : ""}`} onClick={(e) => setBedroomQuantityByCheckbox("4+", e)}>4+</span>
                    </div>
                </div>
            }
            {!["Sítio/Fazenda", "Área Comercial", "Terreno", "Terreno Comercial", "Terreno Em Condomínio"].includes(selectboxValue) && 
                <div className="spec">
                    <span className="title">Vagas Garagem</span>
                    <div className="checkbox_select">
                        <span className={`checkbox${garageQuantity == 1 ? " active" : ""}`} onClick={(e) => setGarageQuantityByCheckbox("1", e)}>1</span>
                        <span className={`checkbox${garageQuantity == 2 ? " active" : ""}`} onClick={(e) => setGarageQuantityByCheckbox("2", e)}>2</span>
                        <span className={`checkbox${garageQuantity == 3 ? " active" : ""}`} onClick={(e) => setGarageQuantityByCheckbox("3", e)}>3</span>
                        <span className={`checkbox${garageQuantity == 4 ? " active" : ""}`} onClick={(e) => setGarageQuantityByCheckbox("4", e)}>4</span>
                        <span className={`checkbox${garageQuantity == "4+" ? " active" : ""}`} onClick={(e) => setGarageQuantityByCheckbox("4+", e)}>4+</span>
                    </div>
                </div>
            }
        </div>
        <div className="specs_three">
            <div className="spec">
                <span className="title">Valor do Aluguel</span>
                <div className="inputbox">
                    <span className="prefix">R$</span>
                    <input type="text" placeholder="850.000,00" value={price} onChange={handlePrice} />
                </div>
            </div>
            {selectboxValue === "Apartamento" && 
                <>
                    <div className="spec special">
                        <span className="title">Condomínio</span>
                        <div className="inputbox">
                            <span className="prefix">R$</span>
                            <input type="text" placeholder="5.000" value={condominiumPrice} onChange={handleCondominium} />
                        </div>
                    </div>
                    <div className="spec special">
                        <span className="title">IPTU</span>
                        <div className="inputbox">
                            <span className="prefix">R$</span>
                            <input type="text" placeholder="5.000" value={IPTU} onChange={handleIPTU} />
                        </div>
                    </div>
                </>
            }
            <div className="spec">
                <span className="title">Aceita Permuta</span>
                <div className="buttons">
                    <span 
                        className={`button ${acceptsExchange ? "actived" : ""}`}
                        onClick={() => setAcceptsExchange(!acceptsExchange)}
                    >
                        Sim</span>
                    <span 
                        className={`button ${!acceptsExchange ? "actived" : ""}`}
                        onClick={() => setAcceptsExchange(!acceptsExchange)}  
                    >
                        Não
                    </span>
                </div>
            </div>
        </div>
        {!["Terreno", "Terreno Comercial", "Terreno Em Condomínio"].includes(selectboxValue) &&

        <div className="specs_four">
            {inputs.map((input) => (
                <div key={input.id} className="spec">
                    <input
                        type="radio"
                        id={input.id}
                        checked={!!selectedInputs[input.id]}
                        onClick={() => handleToggle(input.id)}
                        readOnly
                    /> 
                    <label htmlFor={input.id}>{input.label}</label>
                </div>
            ))}
        </div>
        }
        <div className="specs_five">
            <span className="title">Descreva seu imóvel</span>
            <textarea name="" id="" value={description} onChange={handleSetDescription}></textarea>
            <span className="info">{description.length} de 1000 caracteres</span>
        </div>
        <div className="specs_six">
            <div className="spec">
                <span className="title">CEP</span>
                <input type="text" placeholder="15000-000" value={CEP} onChange={e => setCEP(e.currentTarget.value)} />
            </div>
            <div className="spec">
                <span className="title">Cidade</span>
                <input type="text" placeholder="São José do Rio Preto" className="s" value={city} onChange={e => setCity(e.currentTarget.value)} />
            </div>
            <div className="spec">
                <span className="title">Endereço</span>
                <input type="text" placeholder="Av. Alberto Andaló" className="s" value={street} onChange={e => setStreet(e.currentTarget.value)} />
            </div>
            <div className="spec">
                <span className="title">Bairro</span>
                <input type="text" placeholder="Dhama" className="s" value={neightboard} onChange={e => setNeightboard(e.currentTarget.value)} />
            </div>
            <div className="spec">
                <span className="title">Estado</span>
                <input type="text" placeholder="São Paulo" className="s" value={state} onChange={e => setState(e.currentTarget.value)} />
            </div>
            <div className="spec">
                <span className="title">Número/Complemento</span>
                <div className="input-container">
                    <input type="text" placeholder="1050" value={number} onChange={e => setNumber(e.currentTarget.value)} />
                    <input type="text" placeholder="QD01LT02" value={complement} onChange={e => setComplement(e.currentTarget.value)} />
                </div>
            </div>
            
        </div>
        <div className="specs_seven">
            <span className="title">Adicione fotos do seu imóvel</span>
            <span className="description title">*Até 20 Imagens</span>
            <ImageUpload initialImages={[]} images={images} setImages={setImages} />
            <span className="description">Seus dados são mantidos em sigilo por Alugue Imóveis e não são compartilhados com empresas de serviço similares.</span>
        </div>
        <div className="next-container">
            <div className="description" onClick={() => setShowContactUser(!showContactUser)}>
                <div className={`checkbox${showContactUser ? " checked" : " unchecked"}`}>
                    <FaCheck />
                </div>
                Deseja exíbir seus dados de contato no anúncio?
            </div>
            {true  ? 
                <span className="button finish" onClick={next}>
                    Avançar
                </span>
                :
                <span className="button deactive" onClick={() => console.log(formatPrice())}>
                    Avançar
                </span>
            }
        </div>
    </WrapperRegisterProperty>
}

export default EditPropertyPage