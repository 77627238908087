import WrapperProperty from "./Property.styles";
import { MdContentCopy } from "react-icons/md";
import { IoBedOutline } from "react-icons/io5";
import { FaShower } from "react-icons/fa";
import { IoCarOutline } from "react-icons/io5";
import { PiHeart } from "react-icons/pi";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { addDoc, arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db, toggleFavorite } from "../../hooks/firebase";


const Property = ({data}) => {
    const navigate = useNavigate()
    const {currentUser, userData} = useContext(AuthContext)
    const [favorited, setFavorited] = useState(data.likedBy.includes(currentUser))
    
    const handleCreateChat = () => {
        createChat().then(chatId => {
            if (chatId) navigate(`/chat/${chatId}`)
        })
    }

    const handleToggleFavorite = async () => {
        if (currentUser && currentUser !== data.ownerId) {
            toggleFavorite(currentUser, data.id)
            setFavorited(!favorited)
        }
    }

    const createChat = async () => {
        try {
          const user = currentUser;
          if (user === data.ownerId || user === null) return false;
          const userRef = doc(db, "users", data.ownerId);
          const userOwner = (await getDoc(userRef)).data();

          const chatQuery = query(
            collection(db, "chats"),
            where("propertyId", "==", data.id),
            where("ownerId", "==", data.ownerId),
            where("participantId", "==", user)
          );
      
          const querySnapshot = await getDocs(chatQuery);
      
          if (!querySnapshot.empty) {
            return querySnapshot.docs[0].id;
          } else {
            const novoChat = await addDoc(collection(db, "chats"), {
              propertyId: data.id,
              ownerId: data.ownerId,
              ownerName: userOwner?.name,
              participantId: user,
              participant: { name: userData.name },
              body: [],
            });
            return novoChat.id; 
          }
        } catch (err) {
          console.log(err)
        }
    };
    
    function viewProperty () {
        navigate(`/imovel/${data.id}`)
    }

    if (data.deletedAt !== null) {
        return <></>;
    }

    return <WrapperProperty>
        {/* <div className="top">
            <div className="infos">
                {data.furnitured && 
                    <div className="info">
                        <LuArmchair />
                        <span>Mobiliado</span>
                    </div>
                }
                {data.pets && 
                    <div className="info">
                        <MdPets />
                        <span>Aceita Pets</span>
                    </div>
                }
                {data.pool && 
                    <div className="info">
                        <FaWaterLadder />
                        <span>Piscina</span>
                    </div>
                }
                {data.balcony && 
                    <div className="info">
                        <MdBalcony />
                        <span>Varanda</span>
                    </div>
                }
            </div>
            <div className="actions">
                {/*<div className="action">
                    <span>Ordenar por</span>
                    <FaChevronDown />
                </div>
                <div className="action">
                    <IoMapSharp />
                    <span>Mapa</span>
                </div>
            </div>
        </div> */}
        <div className="property-content">
            <div className="img" onClick={viewProperty}>
                <img src={data.images[0]} alt="" />
            </div>
            <div className="content">
                <div className="about">
                    <div className="left">
                        <span className="property-neightboard">
                            {data.neightboard}, {data.city}
                        </span>
                        <span className="property-road">
                            {data.street}
                        </span>
                        <span className="property-description">
                            {data.description.slice(0, 35)} <br />
                            {data.description.length > 35 
                                ? data.description.slice(35, 55) + "..." 
                                : ""}
                        </span>
                        <div className="property-infos">
                            {data.squareMeter && 
                                <div className="info">
                                    <MdContentCopy />
                                    <span>{data.squareMeter} m²</span>
                                </div>
                            }
                            {["Apartamento", "Sala Comercial", "Kitnet/Studio"].includes(data.type) && 
                                <div className="info">
                                    <MdContentCopy />
                                    <span>{data.squareConstruction} m²</span>
                                </div>
                            }
                            {data.alqueiro && 
                                <div className="info">
                                    <MdContentCopy />
                                    <span>{data.alqueiro}</span>
                                </div>
                            }
                            {data.bedroom && 
                                <div className="info">
                                    <IoBedOutline />
                                    <span>{data.bedroom}</span>
                                </div>
                            }
                            {data.bathroom && 
                                <div className="info">
                                    <FaShower />
                                    <span>{data.bathroom}</span>
                                </div>
                            }
                            {data.garage && 
                                <div className="info">
                                    <IoCarOutline />
                                    <span>{data.garage}</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="action">
                        {favorited ? 
                            <span className={favorited ? 'actived' : ""} onClick={handleToggleFavorite}><PiHeart /></span>
                            :
                            <span className={favorited ? 'actived' : ""} onClick={handleToggleFavorite}><PiHeart /></span>
                        }
                    </div>
                </div>
                <div className="more">
                    <div className="costs">
                        <span className="price">R$ {parseInt(data.price).toLocaleString("pt-BR")+",00"}</span>
                        <span className="additional">{data.additional}</span>
                        {data.info && 
                            <span className="info">
                                <RiMoneyDollarCircleFill />
                                Preço abaixo do valor de mercado
                            </span>
                        }
                    </div>
                    <div className="contact">
                        {/* <span className="button telephone">Telefone</span> */}
                        <span className="button chat" onClick={handleCreateChat}>Mensagem</span>
                    </div>
                </div>
            </div>
        </div>
    </WrapperProperty>
}

export default Property