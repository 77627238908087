import styled from "styled-components";
import theme from "../../theme";

const WrapperNavBar = styled.div`
    width: 27%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    border: 2px solid #c3c3c3;
    position: relative;
    z-index: 10;

    .cropper-modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        z-index: 1000;
        opacity: 1;
    }

    .modal-overlay {
        position: absolute !important;
        width: 0;
        height: 0;
    }

    .user-container {
        width: 60%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 30px 0;
        margin-bottom: 20px;
        gap: 15px;

        .top {
            width: 100%;
            display: flex;
            gap: 12px;
            align-items: center;

            img {
                width: 70px;
                height: 70px;
                border-radius: 100px;
                cursor: pointer;
            }

            .user-img {
                display: flex;
                position: relative;
                cursor: pointer;

                .user-icon {
                    width: 70px;
                    height: 70px;
                    border-radius: 100px;
                    background-color: #c3c3c3;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 2rem;
                    color: gray;
                }

                .icon-camera {
                    position: absolute;
                    font-size: 1.2rem;
                    bottom: 0;
                    right: 0;
                }
            }

            .user {
                display: flex;
                flex-direction: column;

                .name {
                    font-size: 1.1rem;
                    font-weight: 600;
                }

                .about {
                    font-size: .9rem;
                }
            }
        }

       
    }

    .menu {
        width: 90%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition-property: background;
        transition-duration: .3s;
        gap: 10px;

        .navigate-item{
            cursor: pointer;
            width: 100%;
            padding: 2px 0px;
            text-decoration: none;
            color: #0a0a0a;
            display: flex;
            gap: 5px;
            align-items: center;
            font-size: 1.2rem;
            border-radius: 100px;

            svg {
                margin-left: 20px;
                font-size: 2.1rem;
                color: #01bbb6;

                &.invert{
                    transform: scaleX(-1);
                }
            }

            &:hover {
                background-color: #e9fffe;
            }

            &.active {
                color: white;
                background-color: #01bbb6;
                

                svg {
                    color: white;
                }
            }
            
        }


    }

    .button {
        width: 100%;
        padding: 4px 0px;
        text-align: center;
        border-radius: 100px;
        background-color: #ff433d;
        color: white;
        cursor: pointer;
        text-decoration: none;

        &.s{
            position: absolute;
            bottom: 0;
            margin-bottom: -60px;
            background-color: #01bbb6;
            padding: 5px 0px;
            font-size: 1.375rem;
        }
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        display: none;
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        width: 35%;

        .user-container {
            .top {
                .user {
                    .name {
                        font-size: .9rem;
                    }
                }
            }
        }

        .menu {
            a {
                font-size: 1rem;

                svg {
                    font-size: 2rem;
                }
            }
        }

        .button {
            &.s {
                font-size: 1rem;
                margin-bottom: -50px;
            }
        }
        
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        width: 30%;

        .user-container {
            .top {
                .user {
                    .name {
                        font-size: .9rem;
                    }
                }
            }
        }

        .menu {
            a {
                font-size: 1.1rem;

                svg {
                    font-size: 2.2rem;
                }
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        width: 30%;
        .user-container {
            .top {
                .user {
                    .name {
                        font-size: .9rem;
                    }
                }
            }
        }

        .menu {
            a {
                font-size: 1.1rem;

                svg {
                    font-size: 2.2rem;
                }
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        
    }
    
`

export default WrapperNavBar