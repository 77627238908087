import React, { useState, useRef, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../hooks/firebase";
import { uploadProfileImage } from "../../hooks/firebase";
import { AuthContext } from "../../context/AuthContext";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import WrapperNavBar from "./NavBar.styles";
import { FaUserAlt, FaCamera, FaRegHeart } from "react-icons/fa";
import WrapperModal from "./Modal.styles";
import { PiUserCircle } from "react-icons/pi";
import { IoMegaphoneOutline } from "react-icons/io5";
import { LuClock3 } from "react-icons/lu";
import { RiFolderSharedLine, RiLockPasswordLine } from "react-icons/ri";
import { BsEyeSlash } from "react-icons/bs";
import { LiaClipboardListSolid } from "react-icons/lia";
import { NotificationContext } from "../../context/NotificationContext";
import { getModel } from "../../hooks/loadModel";
import NsfwDetection from "../../hooks/NsfwDetection";

const NavBar = (props) => {
    const setShow = props.handle;
    const { currentUser, userData, setUserData } = useContext(AuthContext);
    const data = userData;
    const containsImg = data?.photoURL !== "";
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const {addNotification} = useContext(NotificationContext) 
    const [image, setImage] = useState(null); // Imagem selecionada para recortar
    const [cropper, setCropper] = useState(null); // Referência ao cropper
    const [cropping, setCropping] = useState(false); // Controle do modal de recorte
    const [isSaving, setIsSaving] = useState(false); // Controle para o estado de "Salvando"

    // Função para capturar o arquivo selecionado pelo usuário
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result); // Exibe a imagem no Cropper
                setCropping(true); // Ativa o modal de recorte
            };
            reader.readAsDataURL(file);
        }
    };
    
    const saveCroppedImage = async () => {
        if (cropper) {
            setIsSaving(true);
            cropper.getCroppedCanvas().toBlob(async (blob) => {
                if (blob) {
                    const model = await getModel();
                    const result = await NsfwDetection(blob, model)
                    if (result) {
                        uploadProfileImage(currentUser, blob).then((result) => {
                            if (result) {
                                const userRef = doc(db, "users", currentUser);
                                updateDoc(userRef, { photoURL: result }).then(() => {
                                    setUserData((prevUserData) => ({
                                        ...prevUserData,
                                        photoURL: result,
                                    }));
                                    setCropping(false); // Fecha o modal
                                    setIsSaving(false); // Desabilita o estado de "Salvando"
                                });
                                addNotification("Imagem alterada", "success")
                            } else {
                                console.error("Falha ao atualizar a imagem de perfil.");
                                setIsSaving(false); // Desabilita o estado de "Salvando" mesmo em caso de erro
                            }
                        });
                    }
                } else {
                    console.error("Erro ao converter a imagem recortada em um Blob.");
                    setIsSaving(false); // Desabilita o estado de "Salvando" em caso de erro
                }
            }, "image/jpeg"); // Formato de saída da imagem
        }
    };

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const logout = () => {
        const auth = getAuth();
        try {
            signOut(auth);
            navigate("/entrar");
            localStorage.removeItem("viewedProperties");
        } catch (error) {
            console.error("Erro ao deslogar: ", error);
        }
    };

    if (!data) return null;

    return (
        <>
            <WrapperNavBar>
                <div className="user-container">
                    <div className="top">
                        {!containsImg ? (
                            <div className="user-img" onClick={handleImageClick}>
                                <div className="user-icon">
                                    <FaUserAlt />
                                </div>
                                <FaCamera className="icon-camera" />
                            </div>
                        ) : (
                            <img
                                src={data.photoURL}
                                alt="Imagem de perfil"
                                className="user-img"
                                onClick={handleImageClick}
                            />
                        )}
                        <div className="user">
                            <span className="name">{data.name}</span>
                            <span className="about">{data.about}</span>
                        </div>
                    </div>

                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleFileChange}
                    />

                    <NavLink className="button" to="/meus-chats">
                        Chat
                    </NavLink>
                </div>
                <div className="menu">
                    <NavLink className="navigate-item" to="/meus-dados">
                        <PiUserCircle /> Meus Dados
                    </NavLink>
                    <NavLink className="navigate-item" to="/meus-imoveis">
                        <IoMegaphoneOutline /> Anúncios
                    </NavLink>
                    <NavLink className="navigate-item" to="/meus-imoveis-favoritos">
                        <FaRegHeart /> Favoritos
                    </NavLink>
                    <NavLink className="navigate-item" to="/vistos-por-ultimo">
                        <LuClock3 /> Visto por último
                    </NavLink>
                    <NavLink className="navigate-item" to="/alterar-senha">
                        <RiLockPasswordLine /> Alterar Senha
                    </NavLink>
                    <NavLink className="navigate-item" to="/institucional">
                        <BsEyeSlash className="invert" /> Política de Privacidade
                    </NavLink>
                    <NavLink className="navigate-item" to="/meus-termos-de-uso">
                        <LiaClipboardListSolid /> Termos de Uso
                    </NavLink>
                    <a className="navigate-item" onClick={logout}>
                        <RiFolderSharedLine /> Finalizar sessão
                    </a>
                </div>
            </WrapperNavBar>

            {cropping && (
                <WrapperModal>
                    <div className="modal-content">
                        <h2>Recorte sua Imagem</h2>
                        <Cropper
                            src={image}
                            style={{ height: 400, width: "100%" }}
                            initialAspectRatio={1}
                            aspectRatio={1}
                            guides={true}
                            viewMode={1}
                            minCropBoxHeight={100}
                            minCropBoxWidth={100}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            onInitialized={(instance) => setCropper(instance)}
                        />
                        <div className="modal-actions">
                            {isSaving ? 
                                <button className="btn-save">
                                    Salvando
                                </button>
                                :
                                <button className="btn-save" onClick={saveCroppedImage}>
                                    Salvar
                                </button>
                            }
                            
                            <button className="btn-cancel" onClick={() => setCropping(false)}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </WrapperModal>
            )}
        </>
    );
};

export default NavBar;
