import styled from "styled-components";
import theme from "../../theme";

const WrapperProperty = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .infos {
            display: flex;
            align-items: center;
            gap: 5px;
            width: 100%;

            .info {
                display: flex;
                gap: 5px;
                align-items: center;
                border: 1px solid black;
                border-radius: 50px;
                padding: 1px 10px;
                
                span,svg {
                    font-size: .775rem;
                }
            }
        }

        .actions {
            display: flex;
            gap: 12px;

            .action {
                display: flex;
                gap: 5px;
                align-items: center;
                font-size: .775rem;
                white-space: nowrap;
                

                &:last-child{
                    padding: 8px 25px;
                    background-color: #00d5bf;
                    color: white;
                    border-radius: 10px;

                   
                }
            }
        }
    }

    .property-content{
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        border: 1px solid #ededee;

        .img {
            width: 45%;
            position: relative;
            cursor: pointer;
            overflow-x: hidden;

            img {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                object-fit: cover;
                position: absolute; 
                width: 100%;
                height: 100%; 
                top: 0;
                left: 0;
            }
        }

        .content {
            width: calc(54% - 30px);
            padding: 15px 15px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            
            .about {
                display: flex;
                align-items: start;
                
                .left {
                    width: 80%;
                    display: flex;
                    flex-direction: column;
                    gap: 3px;

                    .property-neightboard {
                        font-size: .875rem;
                        font-weight: 600;
                        color: #646872;
                    }

                    .property-road {
                        font-size: .775rem;
                        color: #646872;
                        font-weight: 600;
                    }

                    .property-description {
                        font-size: .775rem;
                        color: #646872;
                    }

                    .property-infos {
                        display: flex;
                        gap: 30px;

                        .info {
                            display: flex;
                            align-items: center;
                            gap: 2px;
                            font-size: .875rem;
                            color: #646872;
                        }
                    }
                }

                .action {
                    width: 20%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-end;
                    cursor: pointer;

                    span.actived {
                        svg {
                            background-color: ${theme.fontColor.green};
                            color: white;
                        }
                    }

                    svg {
                        padding: 7px;
                        border-radius: 10px;
                        border: 2px solid #ededee;
                        color: ${theme.fontColor.green};
                        font-size: 1.2rem;
                        font-weight: 600;
                    }
                }
            }

            .more {
                display: flex;
                justify-content: space-between;
                
                .costs {
                    font-size: .775rem;
                    display: flex;
                    flex-direction: column;

                    .price, .info {
                        color: #258e51;
                        font-weight: 600;
                        font-size: 1rem;
                    }

                    .additional {
                        color: #646872;
                        font-weight: 600;
                    }
                }

                .contact {
                    font-weight: 600;
                    font-size: .785rem;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .telephone {
                        color: #01d7c5;
                        
                    }

                    .chat {
                        background-color: #01d7c5;
                        color: white;
                        padding: 9px 15px;
                        border-radius: 8px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        .top {
            flex-wrap: wrap;

            .infos {
                flex-wrap: wrap;
                margin-bottom: 10px;

                .info {
                    margin-bottom: 20px;
                    width: calc(48% - 25px);

                    span,svg{
                        font-size: 1rem;
                    }

                }
            }
        } 
        
        .property-content{
            flex-wrap: wrap;
            
            .img {
                height: 250px;
                width: 100%;
                border-radius: 10px;
            }

            .content {
                width: calc(100% - 30px);
                .more {
                    margin-top: 10px;
                    flex-direction: column;
                    gap: 20px;

                    .costs,.contact{
                        width: 100%;
                    }

                    .contact {
                        align-self: flex-start;
                    }
                }
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        .property-content{
            .img {
                width: 100%;
            }

            .content {
                .about {
                    .left {
                        .property-neightboard {
                            font-size: .8rem;
                        }
                        .property-road {
                            font-size: .73rem;
                        }
                        .property-description {
                            font-size: .73rem;
                        }
                    }
                }

                .more{
                    
                }
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        
    }
    
`

export default WrapperProperty