import React, { useContext, useState } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../theme';
import { MdContentCopy } from 'react-icons/md';
import { IoBedOutline, IoCarOutline } from 'react-icons/io5';
import { FaShower } from 'react-icons/fa';
import { AuthContext } from '../../context/AuthContext';
import { addDoc, collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../hooks/firebase';

const mapContainerStyle = {
  height: "100%",
  width: "100%"
};

const center = {
  lat: -20.8176,
  lng: -49.3775
};

const icon = {
  url: "https://cdn-icons-png.flaticon.com/512/484/484167.png",
  scaledSize: 30
};

const WrapperInfoCard = styled.div`

  img {
    margin-bottom: 10px;
  }

  .location {
    font-family: ${theme.fontFamily.medium};
  }


  .room-info {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;

    .info {
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }

  .price {
    font-family: ${theme.fontFamily.medium};
    margin-top: 20px;
  }

  .actions {
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 80%;

    ${props => props.showWhatsapp ? `
        justify-content: space-between;
    ` : `
        justify-content: center;
    `}



    .whats {
      color: ${theme.fontColor.green};
    }

    .chat {
      padding: 5px 15px;
      background: ${theme.fontColor.green};
      color: white;
      border-radius: 5px;
    }
  }

  
`

const MyMapComponent = (props) => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const {currentUser, userData} = useContext(AuthContext)
  const navigate = useNavigate();

  const formatPrice = (price) => {
    return price.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 0
    }).replace("R$", "");
  }

  const createCustomIcon = (price) => {
    const formattedPrice = price.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 0
    }).replace("R$", "");
  
    const width = Math.max(30, formattedPrice.length * 6 + 16); // Largura menor ajustada
    const height = 40; // Altura reduzida
  
    const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">
        <!-- Retângulo com cor tema e bordas arredondadas -->
        <rect x="5" y="5" width="${width - 10}" height="25" rx="6" ry="6" fill="#01bbb6"/>
  
        <!-- Texto do preço em branco -->
        <text x="${width / 2}" y="22" text-anchor="middle" font-size="10" font-family="Nunito-medium" font-weight="bold" fill="white">
          ${formattedPrice}
        </text>
      </svg>
    `;
  
    return {
      url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
      scaledSize: { width, height }, // Largura e altura ajustadas
      anchor: { x: width / 2, y: 35 } // Ponto de ancoragem ajustado
    };
  };
  
  const handleMarkerClick = (place) => {
    setSelectedPlace(place);
  };

  const handleCloseInfoWindow = () => {
    setSelectedPlace(null);
  };

  const handleCreateChat = () => {
    createChat().then(chatId => {
        if (chatId) {
            navigate(`/chat/${chatId}`)
        }
    })
  }

  const createChat = async () => {
    try {
      const user = currentUser;
      const data = selectedPlace
      
      if (user === data.ownerId) {
        alert("Você não pode abrir um chat para seu próprio anúncio");
        return false;
      }
  
      const userRef = doc(db, "users", data.ownerId);
      const userOwner = (await getDoc(userRef)).data();

  
      const chatQuery = query(
        collection(db, "chats"),
        where("propertyId", "==", data.id),
        where("ownerId", "==", data.ownerId),
        where("participantId", "==", user)
      );
  
      const querySnapshot = await getDocs(chatQuery);
  
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].id;
      } else {
        const novoChat = await addDoc(collection(db, "chats"), {
          propertyId: data.id,
          ownerId: data.ownerId,
          ownerName: userOwner?.name,
          participantId: user,
          participant: { name: userData.name },
          body: [],
        });
        return novoChat.id; 
      }
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={12}
    >
      {props.properties && props.properties.map(place => (
        <Marker 
          key={place.id}
          position={{ lat: place.coords.latitude, lng: place.coords.longitude }}
          icon={createCustomIcon(place.price)}
          title={place.name}
          onClick={() => handleMarkerClick(place)}
          onMouseOver={() => handleMarkerClick(place)}
        />
      ))}

      {selectedPlace && (
        <InfoWindow
          position={{
            lat: selectedPlace.coords.latitude + 0.010, // Ajuste para a InfoWindow aparecer mais acima
            lng: selectedPlace.coords.longitude
          }}
          onCloseClick={handleCloseInfoWindow}
        >
          <WrapperInfoCard showWhatsapp={false}>
            <img onClick={() => navigate(`/imovel/${selectedPlace.id}`)} src={selectedPlace.images[0]} alt="" style={{width: "100%", height: "130px", cursor: "pointer"}} />
            <div className="location">
              {`${selectedPlace.street} ${selectedPlace.number} ${selectedPlace.neightboard}`.slice(0, 35)}
            </div>
            <div className="location">
              {(() => {
                const secondLine = `${selectedPlace.street} ${selectedPlace.number} ${selectedPlace.neightboard}`.slice(35, 55);
                return secondLine ? `${secondLine}...` : secondLine;
              })()}
            </div>
            <div className="room-info">
              <span className="info">
                  <MdContentCopy />
                  {selectedPlace.squareConstruction} m²
              </span>
              <span className="info">
                  <IoBedOutline />
                  {selectedPlace.bedroom}
              </span>
              <span className="info">
                  <FaShower />
                  {selectedPlace.bathroom}
              </span>
              <span className="info">
                  <IoCarOutline />
                  {selectedPlace.garage}
              </span>
            </div>
            <div className="price">
              R$ {formatPrice(selectedPlace.price)+",00"}
            </div>
            <div className='actions'>
              <span className='chat' onClick={handleCreateChat}>Mensagem</span>
              {/* <span className="whats">Whatsapp</span> */}
            </div>
          </WrapperInfoCard>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default MyMapComponent;
