import styled from "styled-components";
import theme from "../../theme";

const WrapperChatContainer = styled.div`
    margin: 30px 13%;
    display: flex;
    justify-content: center;
    position: relative;

    .container-chat{
        display: flex;
        flex-direction: column;
        width: 55%; 
        gap: 20px;
        margin-left: 50px;
        margin-top: 50px;
        position: relative;
        height: 60vh;

        .main {
            width: 70%;
            border-radius: 17px;
            position: relative;
            margin-top: -20px;

            .back {
                position: absolute;
                margin-top: -20px;
                cursor: pointer;
            }


            .header-chat {
                background-color: #e5fffe;
                display: flex;
                align-items: center;
                gap: 10px;
                border: 1px solid gray;
                border-radius: 17px;
                padding: 5px 0px;
                flex-wrap: wrap;
                transform: translateY(15px);

                .user-icon {
                    width: 60px;
                    height: 60px;
                    border-radius: 100px;
                    background-color: #c3c3c3;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 2rem;
                    color: gray;
                    margin-left: 20px;
                    border: 2px solid white;
                }
                

                img {
                    margin-left: 20px;
                    border-radius: 100px;
                    border: 2px solid white;
                    width: 60px;
                    height: 60px;
                }

                .username {
                    font-size: 1.2rem;
                    font-weight: 600;
                }
            }

            .body-chat {
                background-color: #e5fffe;
                overflow: auto;
                display: flex;
                flex-direction: column;
                gap: 10px;
                height: 50vh;
                overflow-y: visible;
                border: 1px solid gray;
                border-top: none;
                border-bottom: none;

                &::-webkit-scrollbar {
                    width: 10px; 
                }

                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                &::-webkit-scrollbar-thumb {
                    background: #888; 
                    border-radius: 5px; 
                }

                .space {
                    height: 40px;
                }

                .message {
                    width: 90%;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin: 0 auto;
                    padding-bottom: 10px;

                    span {
                        width: 80%;
                    }

                    .username {
                        font-size: 1rem;
                        font-weight: 600;
                    }

                    .moment{
                        font-size: .7rem;
                    }

                    &.received {
                        width: 80%;
                        margin-left: 5%;
                        margin-right: 15%;
                    }

                    &.sended{
                        width: 80%;
                        margin-left: 15%;
                        margin-right: 5%;
                        align-self: flex-end;
                        display: flex; 
                        text-align: end;

                        span {
                            width: 100%;
                            text-align: end;
                        }
                    }
                }
            }

            .inputbox {
                border: 1px solid gray;
                border-top: none;
                border-bottom-left-radius: 17px;
                border-bottom-right-radius: 17px;
                background-color: #e5fffe;


                .input-container {
                    transform: translateY(-5px);
                    border-radius: 100px;
                    width: 95%;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: white;
                    border: 1px solid gray;
                    padding: 4px 0px;

                    input {
                        width: calc(80%);
                        border-radius: 100px;
                        border: none;
                        outline: none;
                        padding: 2px 12px;
                        font-size: 1.15rem;
                    }

                    svg {
                        margin-right: 12px;
                    }
                }
            }
        }
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin: 1px;

        .container-chat{
            position: relative;
            margin: 0;
            height: auto;
            width: 100%;

            .back {
                display: none;
            }

            .main {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                height: 100%;

                .body-chat {
                    height: 100%;
                    align-self: flex-start;
                }

                .header-chat{
                    width: 100%;
                }

                .body-chat {
                    width: 100%;
                }

                .inputbox{
                    width: 100%;
                    align-self: flex-end;
                }
            }
        }
    }

`

export default WrapperChatContainer