import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import theme from '../../theme';
import { useNavigate } from 'react-router-dom';

function getWidthByVisibleItems(visibleItems) {
  let Width = "";
  let FactorToMultiple = 0;

  if (visibleItems === 4) {
    Width = "width: calc(25% - 20px);";
    FactorToMultiple = 100;
  }

  if (visibleItems === 2) {
    Width = "width: calc(50% - 20px);";
    FactorToMultiple = 200;
  }

  if (visibleItems === 1) {
    Width = "width: calc(100% - 20px);";
    FactorToMultiple = 100;
  }

  return [Width, FactorToMultiple];
}

const CarouselContainer = styled.div`
  margin: 0 18%;
  margin-top: 120px;
  overflow: hidden;

  @media (max-width: ${theme.breackpoint.mobile_2}) {
      display: none;
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: translateX(-${props => ((props.currentIndex * props.factorToMultiple) / props.visibleItems)}%);
`;

const Card = styled.div`
  flex: 0 0 auto;
  ${props => props.width}
  margin: 0 10px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  img {
    border-radius: 8px;
    height: 100%;
    width: 100%;
  }
`;

const ControllersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 20px;
  margin-right: 10px;
  border-radius: 8px;

  @media (max-width: ${theme.breackpoint.mobile_2}) {
    margin-top: 15px;
    margin-bottom: 30px;
  }
`;

const Controller = styled.div`
  padding: 17px;
  border-radius: 15px;
  border: 2px solid #dce0e1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease; 
  cursor: pointer;
  user-select: none;

  svg {
    color: ${theme.fontColor.green};
    transition: color 0.3s ease;
    font-size: .8rem;
  }

  &:hover {
    background-color: ${theme.fontColor.green};
    
    svg {
      color: #dce0e1;
    }
  }
`;

const ActualSlidePanel = styled.div`
  user-select: none;
`;

const Carousel = ({ items, visibleItems }) => {
    const navigate = useNavigate()
    const returnMaxScroll = () => {
        return items.length - 1;
    };

    const [currentIndex, setCurrentIndex] = useState(0);
    const [Width, FactorToMultiple] = getWidthByVisibleItems(visibleItems);
    const maxOfSlideshow = returnMaxScroll();

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
        prevIndex >= maxOfSlideshow ? 0 : prevIndex + 1
        );
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
        prevIndex <= 0 ? maxOfSlideshow : prevIndex - 1
        );
    };

    useEffect(() => {
      const interval = setInterval(() => {
          handleNext();
      }, 7000);

      return () => clearInterval(interval);
    }, [currentIndex]);


    return (
        <CarouselContainer>
        <CarouselWrapper
            currentIndex={currentIndex}
            visibleItems={visibleItems}
            factorToMultiple={FactorToMultiple}
        >
            {items.map((item, index) => (
            <Card key={index} visibleItems={visibleItems} width={Width}>
                <img src={item.banner} alt="" onClick={() => navigate(item.to)} />
            </Card>
            ))}
        </CarouselWrapper>
        {/* <ControllersContainer>
            <Controller onClick={handlePrev}>
            <FaChevronLeft />
            </Controller>
            <ActualSlidePanel>
            {currentIndex + 1} de {maxOfSlideshow + 1}
            </ActualSlidePanel>
            <Controller onClick={handleNext}>
            <FaChevronRight />
            </Controller>
        </ControllersContainer> */}
        </CarouselContainer>
    );
};

export default Carousel;
