import WrapperPlan from "./Styles-Plan";
import EyeIcon from "../../imgs/icons/eye_8924350.png"
import NewYearIcon from "../../imgs/icons/new-year_2668935.png"
import ReturnIcon from "../../imgs/icons/return_770444.png"
import HouseCameraIcon from "../../imgs/icons/camera_3326034.png"
import GiftBoxIcon from "../../imgs/icons/gift-box_3438641.png"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import styled from "styled-components";
import theme from "../../theme";
 
const WrapperPrice = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 20px;

    .top {
        display: flex;
        align-items: flex-end;
        gap: 20px;

        .original {
            text-decoration: line-through;
            margin-bottom: -3px;
        }

        .off {
            background-color: ${theme.fontColor.green};
            color: white;
            padding: 2px 12px;
            border-radius: 100px;
        }
    }

    .price {
        font-size: 2.3rem !important;
        color: ${theme.fontColor.green};
        font-family: ${theme.fontFamily.extraBold} !important;
    }

    .sufix{
        color: ${theme.fontColor.green};
    }
`

const Plan = ({data}) => {
    const setPlan = data.setPlan
    const [showButton, setShowButton] = useState(true)
    const navigate = useNavigate()

    function convertValueToReal(value){
        let normalized = parseInt(value) / 100
        return normalized.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
    }

    function handleSetPlan(){
        if (data.containsData === false) {
            navigate("/anunciar")
            return;
        }
        setShowButton(false)
        setPlan({id: data.id, name: data.title})
    }

    return <WrapperPlan order={data.order}>
        {/* {data.bestPlan &&
            <img className="best-plan" src={BestPlanIcon} alt="Melhor Plano" />
        }  */}
        <div className="title">
            {data.price === undefined && 
                <img src={GiftBoxIcon} alt="gift-box-icon" />
            }
            <span>{data.title}</span>
        </div>
        <div className="content">
            <span className="item price">
                {data.price !== 0 ?
                    <WrapperPrice>
                        <div className="top">
                            <span className="original">R$ 449,90</span>
                            <span className="off">44% OFF</span>
                        </div>
                        <div className="bottom">
                            <span className="price">{convertValueToReal(data.price)}</span>
                            <span className="sufix">/ano*</span>
                        </div>
                    </WrapperPrice> 
                    :
                    <WrapperPrice>
                        <div className="top">
                            <span className="original">R$ 259,9</span>
                            <span className="off">100% OFF</span>
                        </div>
                        <div className="bottom">
                            <span className="price">{convertValueToReal(0)}</span>
                        </div>
                    </WrapperPrice> 
                }
            </span>
            <div className="item">
                <img src={EyeIcon} alt="eye-icon" />
                <span>Visibilidade {data.visibility}</span>
            </div>
            <div className="item">
                <img src={NewYearIcon} alt="new-year-icon" />
                <span>{data.activeDays} dias ativo</span>
            </div>
            <div className="item">
                <img src={ReturnIcon} alt="return-icon" />
                <span>Anúncio volta ao topo {data.topCicle} {data.topCicle === "1" ? "Vez" : "Vezes"}</span>
            </div>
            <div className="item">
                <img src={HouseCameraIcon} alt="hous-camera-icon" />
                <span>Até {data.imagesLimit} imagens</span>
            </div>
            {data.labels.map(label => {
                return <div className="item">
                    <FaCheck />
                    <span>{label}</span>
                </div>
            })}
        </div>
        {showButton ? 
            <div className="button" onClick={handleSetPlan} >
                <span>CONTRATAR</span>
            </div>
            :
            <div className="button">
                <span>Salvando...</span>
            </div>
        }
    </WrapperPlan>
}

export default Plan